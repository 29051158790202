.login {
    background: #eeeeee;
    background-size: cover;

    .model {
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .login-form {
            padding: 2rem;
            box-shadow: 0px 2px 13px 0px rgba($color: #000000, $alpha: 0.8);
            border-radius: 5px;
            background-color: #fff;
            width: 20rem;

            .login-form-button {
                width: 100%;
            }
        }
        
    }
}